import { Typography } from '@saesa/ui-kit-front';
import { LOGIN_LOGO, USER_LOGO } from 'assets/images';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from 'config/authConfig';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, actualizarEstadoFlagApp, actualizarEstadoLogin } from 'store';
import { useTranslation } from 'react-i18next';
import ModalFeatureFlags from 'components/ModalBoletaDigital/ModalFeatureFlags';
import { ModalTipoEnum } from 'config';
import useStyles from './HeaderStyles';

const Header = ({ className }: { className?: string }) => {
  /**
   * Variable que maneja el uso de estilos.
   */
  const styles = useStyles();

  /**
   * Uso del i18n para traducciones
   */
  const { t } = useTranslation();

  /**
   * Hook de react para disparar el redux
   */
  const dispatch = useDispatch();

  /**
   * Variable que maneja el valor por defecto del header
   */
  const valorDefecto = t('home:paginas.login.boton');
  /**
   * Variable que maneja el uso de MSAL.
   */
  const { instance, inProgress, accounts } = useMsal();

  /**
   * Variable que maneja el estado de la sesión
   */
  const [estadoSesion, setEstadoSesion] = useState(false);

  /**
   * Variable que maneja el estado del modal
   */
  const [modal, setModal] = useState(false);

  /**
   * Variable que maneja el texto del login
   */
  const [textoLogin, setTextoLogin] = useState(valorDefecto);

  /**
   * Variable que contiene el estado de los cambios en los flags
   */
  const existenCambios = useSelector((state: RootState) => state.feature.estadoCambios);

  /**
   * Función que maneja el login con Azure AD de la aplicación.
   */
  const manejarLoginLogout = () => {
    const cuentas = instance.getAllAccounts();
    if (existenCambios) {
      setModal(true);
    }
    if (cuentas.length > 0 && !existenCambios) {
      instance.logoutRedirect();
    }
    if (cuentas.length <= 0) {
      instance.loginPopup(loginRequest);
    }
  };

  /**
   * Función que permite permanecer en la pagina y conservar los cambios
   */
  const volverALosCambios = () => {
    setModal(false);
  };

  /**
   * Función que permite salir sin guardar cambios
   * @param url que ha sido seleccionada por el usuario en el menu
   */
  const salirSinGuardar = () => {
    dispatch(actualizarEstadoFlagApp(false));
    setModal(false);
    instance.logoutRedirect();
  };

  /**
   * UseEffect que maneja el estado de login con Azure AD de la aplicación.
   */
  useEffect(() => {
    const cuentas = instance.getAllAccounts();

    if (cuentas.length > 0) {
      // Se encuentra logueado
      instance.acquireTokenSilent(loginRequest).then(response => {
        localStorage.setItem('token', response.idToken);
      });
      setEstadoSesion(true);
      setTextoLogin(cuentas[0].username);
      dispatch(actualizarEstadoLogin(true));
    }
    // Se encuentra deslogueado
    if (!modal && cuentas.length <= 0) {
      localStorage.setItem('token', '');
      setEstadoSesion(false);
      setTextoLogin(valorDefecto);
      dispatch(actualizarEstadoLogin(false));
    }
  }, [instance, inProgress, accounts]);

  return (
    <header className={className}>
      <div className={styles.contenedorInterno}>
        <img src={USER_LOGO} alt="user" />
        {!estadoSesion ? (
          <button className={styles.botonCerrarSesion} type="button" onClick={() => manejarLoginLogout()}>
            <Typography className={styles.textoHeader}>{textoLogin}</Typography>
          </button>
        ) : (
          <Typography className={styles.textoHeader}>{textoLogin}</Typography>
        )}

        {estadoSesion && (
          <button className={styles.botonCerrarSesion} type="button" onClick={() => manejarLoginLogout()} data-testid="cerrarSesion">
            <img src={LOGIN_LOGO} alt="user" />
          </button>
        )}
      </div>
      {modal && <ModalFeatureFlags cerrarModal={volverALosCambios} guardarCambios={salirSinGuardar} tipo={ModalTipoEnum.SALIR} />}
    </header>
  );
};

Header.defaultProps = {
  className: '',
};
export default Header;
