import React, { lazy, Suspense } from 'react';
import { Switch } from 'react-router-dom';
import { Loading } from '@saesa/ui-kit-front';
import { APP, OFICINA_VIRTUAL, SITIO_PUBLICO, REPORTES, HOME } from 'root-page';
import { useMsal } from '@azure/msal-react';
import ProtectedRoute from './ProtectedRoute';

const RouterConfig = ({ path }: { path: string }) => {
  /**
   * Variable que maneja el uso de MSAL.
   */
  const { instance } = useMsal();

  /**
   * Variable que maneja el estado de la sesión
   */
  const estadoLogin = instance.getAllAccounts().length > 0;

  /**
   * Importación de pagina de Login
   */
  const PaginaLogin = lazy(() => import('pages/Login/Login'));

  /**
   * Importación de paginas de la app
   */
  const PaginaFlagApp = lazy(() => import('pages/AppPagina/AppPagina'));

  /**
   * Importación de paginas de la oficina virtual
   */
  const PaginaOficinaVirtual = lazy(() => import('pages/OficinaVirtual/OficinaVirtual'));

  /**
   * Importación de paginas del sitio publico
   */
  const PaginaSitioPublico = lazy(() => import('pages/SitioPublico/SitioPublico'));

  /**
   * Importación de paginas de reportes
   */
  const PaginaReportes = lazy(() => import('pages/Reportes/Reportes'));

  return (
    <Suspense fallback={<Loading />}>
      <Switch>
        {/* Ruta para la página de inicio de sesión (no protegida) */}
        <ProtectedRoute path={`${path}${HOME}`} component={PaginaLogin} estadoLogin={estadoLogin} />

        {/* Rutas protegidas */}
        <ProtectedRoute path={`/${APP}`} component={PaginaFlagApp} estadoLogin={estadoLogin} exact />
        <ProtectedRoute path={`/${OFICINA_VIRTUAL}`} component={PaginaOficinaVirtual} estadoLogin={estadoLogin} exact />
        <ProtectedRoute path={`/${SITIO_PUBLICO}`} component={PaginaSitioPublico} estadoLogin={estadoLogin} exact />
        <ProtectedRoute path={`/${REPORTES}`} component={PaginaReportes} estadoLogin={estadoLogin} exact />
      </Switch>
    </Suspense>
  );
};

export default RouterConfig;
