export const GRUPOSAESA_LOGO = `${process.env.REACT_APP_ASSETS_URL}/images/grupo-saesa-logo-blanco.svg`;
export { default as LOGIN_LOGO } from './login-logo.svg'; 
export { default as USER_LOGO } from './user-logo.svg'; 
export { default as ERROR } from './error.svg'; 
export { default as MODAL_GUARDAR_ICONO } from './modal-guardar-icono.svg'; 
export { default as MODAL_ADVERTENCIA_ICONO } from './modal-advertencia-icono.svg';  
export { default as RESET_ICONO } from './reset.svg'; 
export { default as EXPORT_ICONO } from './export.svg'; 
export { default as EXPORT_ICONO_DISABLED } from './export-disabled.svg'; 
export { default as ICONO_ERRROR } from './icono-error.svg'; 
export { default as USER_ICONO } from './user-icono.svg'; 










