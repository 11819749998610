import { Header } from 'components';
import Sidemenu from 'components/Sidemenu/Sidemenu';
import Routes from './navigation/RouterConfig';
import useStyles from './RootStyles';

const Root = ({ path }: { path: string }) => {
  /**
   * Uso del i18n para traducciones
   */
  const styles = useStyles();

  return (
    <section className={styles.home}>
      <Sidemenu className={styles.sidemenu} path={path} />

      <Header className={styles.header} />

      <section className={styles.dashboard}>
        <Routes path={path} />
      </section>
    </section>
  );
};

export default Root;
