import clsx from 'clsx';
import { OficinaVirtualIcon, AppIcon, SitioPublicoIcon, ReportesIcon } from '@saesa/ui-kit-front';
import { APP, OFICINA_VIRTUAL, REPORTES, SITIO_PUBLICO } from 'root-page';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { GRUPOSAESA_LOGO } from 'assets/images';
import MenuItem from './MenuItem/MenuItem';
import useStyles from './SidemenuStyles';

const Sidemenu = ({ className, path }: { className?: string; path: string }) => {
  /**
   * Componente styles
   */
  const styles = useStyles();

  /**
   * Uso del i18n para traducciones
   */
  const { t } = useTranslation();

  /**
   * Hook de react para manejar el login
   */
  const loginActivo = useSelector((state: RootState) => state.feature.estadoLogin);

  /**
   * Arreglo de objetos que contiene los items del menu
   */
  const menuItems = [
    { label: t('home:sidemenu.app'), icon: AppIcon, link: `${path}${APP}`, testId: 'sidemenu-app', exact: true },
    {
      label: t('home:sidemenu.oficinaVirtual'),
      icon: OficinaVirtualIcon,
      link: `${path}${OFICINA_VIRTUAL}`,
      testId: 'sidemenu-oficinaVirtual',
    },
    { label: t('home:sidemenu.sitioPublico'), icon: SitioPublicoIcon, link: `${path}${SITIO_PUBLICO}`, testId: 'sidemenu-sitioPublico' },
    {
      label: t('home:sidemenu.reportes'),
      icon: ReportesIcon,
      link: `${path}${REPORTES}`,
      testId: 'sidemenu-reportes',
    },
  ];

  /**
   * Variable que maneja el estado del menu
   */
  const menuAbierto = false;

  /**
   * Data de servicio seleccionado proveniente de redux
   */

  return (
    <aside
      className={clsx(className, styles.sidemenu, {
        [styles.sidemenuOpen]: menuAbierto,
      })}
    >
      <Link to={APP} className={styles.logo}>
        <img className={styles.logoImg} src={GRUPOSAESA_LOGO} alt="" />
      </Link>
      {loginActivo && (
        <nav className={styles.menu}>
          {menuItems.map(item => (
            <MenuItem
              key={item.label}
              label={item.label}
              icon={item.icon}
              link={item.link}
              mobile
              exact={item?.exact}
              dataTestId={item.testId}
            />
          ))}
        </nav>
      )}
    </aside>
  );
};

Sidemenu.defaultProps = {
  className: '',
};

export default Sidemenu;
