import { createStyles, makeStyles, Theme } from '@material-ui/core';

const SidemenuStyles = makeStyles((theme: Theme) =>
  createStyles({
    sidemenu: {
      position: 'relative',
      display: 'flex',
      flexFlow: 'column',
      alignItems: 'center',
      background: 'var(--blanco)',
      paddingTop: '16px',
      transition: 'var(--transition) all',
      zIndex: 3,
      backgroundColor: 'rgba(41, 40, 40, 1)',
      maxWidth: '16.5vw',
      minHeight: '100vh',
      [theme.breakpoints.down('sm')]: {
        position: 'fixed',
        alignItems: 'flex-start',
        right: 0,
        top: 0,
        bottom: 0,
        width: '300px',
        overflow: 'auto',
        padding: '3.75rem 0 1.75rem 0',
        borderRadius: '0.625rem 0 0 0.625rem',
        transform: 'translateX(100%)',
      },
    },
    sidemenuOpen: {
      transform: 'translateX(0)',
    },
    logo: {
      width: '60%',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    logoImg: {
      width: '100%',
      height: '38px',
    },
    menu: {
      marginTop: '95px',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'wrap',
      alignContent: 'center',
      padding: '0px 32px 0px 32px',
      [theme.breakpoints.down('sm')]: {
        marginTop: '0',
        padding: '1.75rem 0',
      },
    },
    menuUsuario: {
      background: 'var(--gris-l98)',
      width: '100%',
      padding: '1.75rem 0',
      flexGrow: 1,
    },
    perfilUsuario: {
      padding: '0 2rem',
    },
    cerrar: {
      position: 'absolute',
      top: '1.75rem',
      right: '1.75rem',
    },
    divisorCnt: {
      display: 'block',
      width: '100%',
      marginTop: '1.75rem',
      padding: '0 2rem',
    },
    divisor: {
      display: 'block',
      width: '100%',
      borderBottom: '1px solid var(--secundario-gris-azulado)',
    },
  })
);

export default SidemenuStyles;
