/* eslint-disable @typescript-eslint/no-unused-vars */
import axios from 'axios';

const ocultarLoading = () => {
  let contador: string | number | null = sessionStorage.getItem('loading');
  contador = parseInt(contador!, 10);
  if (contador > 0) {
    contador -= 1;
    sessionStorage.setItem('loading', contador.toString());
  }
  if (contador === 0) {
    const loading: any = document.querySelectorAll('#loading');
    loading.forEach((item: any) => item.classList.remove('backdrop--show'));
  }
};

const ErrorInterceptor = () => {
  axios.interceptors.response.use(
    response => {
      ocultarLoading();
      return response;
    },
    error => {
      ocultarLoading();

      return Promise.reject(error);
    }
  );
};

export default ErrorInterceptor;
