import { MODAL_ADVERTENCIA_ICONO, MODAL_GUARDAR_ICONO } from 'assets/images';
import { ModalTipoEnum } from 'config';

/**
 * Función que retorna el texto al titulo del modal
 * @param tipo tipo de modal
 * @param t traducción
 * @returns texto correspondiente al tipo de modal
 */
export const obtenerTituloModal = (tipo: string, t: any) => {
  switch (tipo) {
    case ModalTipoEnum.GUARDAR:
      return t('home:modal.modalGuardar.titulo');
    case ModalTipoEnum.RESTAURAR:
      return t('home:modal.modalRestaurar.titulo');
    case ModalTipoEnum.SALIR:
      return t('home:modal.modalCambiarPestaña.titulo');
    default:
      return '';
  }
};

/**
 * Función que retorna el texto al  subtitulo del modal
 * @param tipo tipo de modal
 * @param t traducción
 * @returns texto correspondiente al tipo de modal
 */
export const obtenerSubTituloModal = (tipo: string, t: any) => {
  switch (tipo) {
    case ModalTipoEnum.GUARDAR:
      return t('home:modal.modalGuardar.subtitulo');
    case ModalTipoEnum.RESTAURAR:
      return t('home:modal.modalRestaurar.subtitulo');
    case ModalTipoEnum.SALIR:
      return t('home:modal.modalCambiarPestaña.subtitulo');
    default:
      return '';
  }
};

/**
 * Función que retorna el texto al  primer boton del modal
 * @param tipo tipo de modal
 * @param t traducción
 * @returns texto correspondiente al tipo de modal
 */
export const obtenerTextoPrimerBoton = (tipo: string, t: any) => {
  switch (tipo) {
    case ModalTipoEnum.GUARDAR:
      return t('home:modal.modalGuardar.botonGuardar');
    case ModalTipoEnum.RESTAURAR:
      return t('home:modal.modalRestaurar.botonRestaurar');
    case ModalTipoEnum.SALIR:
      return t('home:modal.modalCambiarPestaña.botonSalirSinGuardar');
    default:
      return '';
  }
};

/**
 * Función que retorna el texto al boton cancelar del modal
 * @param tipo tipo de modal
 * @param t traducción
 * @returns texto correspondiente al tipo de modal
 */
export const obtenerTextoBotonCancelar = (tipo: string, t: any) => {
  switch (tipo) {
    case ModalTipoEnum.GUARDAR:
      return t('home:modal.modalGuardar.botonCancelar');
    case ModalTipoEnum.RESTAURAR:
      return t('home:modal.modalRestaurar.botonCancelar');
    case ModalTipoEnum.SALIR:
      return t('home:modal.modalCambiarPestaña.botonCancelar');
    default:
      return '';
  }
};

/**
 * Función que obtiene Icono de la ventana modal
 * @param tipo de modal
 * @returns estilos para el contenedor
 */
export const obtenerIconoModal = (tipo: string) => {
  if (tipo === ModalTipoEnum.GUARDAR) {
    return MODAL_GUARDAR_ICONO;
  }
  return MODAL_ADVERTENCIA_ICONO;
};
