import { createSlice } from '@reduxjs/toolkit';
import { FlagsState } from './flagsState.interface';

const initialState: FlagsState = {
  /**
   * Variables guardadas en el redux
   */
  estadoCambios: false,
  urlSeleccionada: '',
  estadoLogin: false,
};

export const homeSlice = createSlice({
  name: 'feature',
  initialState,
  reducers: {
    actualizarEstadoFlagApp: (state, action) => {
      state.estadoCambios = action.payload;
    },
    actualizarUrlSeleccionada: (state, action) => {
      state.urlSeleccionada = action.payload;
    },
    actualizarEstadoLogin: (state, action) => {
      state.estadoLogin = action.payload;
    },
  },
});

export const { actualizarEstadoFlagApp, actualizarUrlSeleccionada, actualizarEstadoLogin } = homeSlice.actions;

export default homeSlice.reducer;
