import { ThemeProvider } from '@mui/material';
import { FormValidation, Loading, theme, Version } from '@saesa/ui-kit-front';
import 'i18n/config';
import { SnackbarProvider } from 'notistack';
import { IPublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { BrowserRouter } from 'react-router-dom';
import useStyles from './AppStyles';
import { ErrorInterceptor, TelemetryProvider, VERSION } from './config';
import { RootRouterConfig } from './navigation';

type AppProps = {
  pca: IPublicClientApplication;
};

function App({ pca }: AppProps) {
  /**
   * Variable que maneja el uso de estilos.
   */
  const styles = useStyles();

  const conectionString = process.env.REACT_APP_INSIGHTS_CONNECTIONSTRING_KEY || '';

  ErrorInterceptor();
  /**
   * Constante que controla la duración de los snackbar
   */
  const hideDuraton = 5000;

  return (
    <MsalProvider instance={pca}>
      <ThemeProvider theme={theme}>
        <SnackbarProvider
          classes={styles}
          maxSnack={6}
          autoHideDuration={hideDuraton}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Version
            showTag={process.env.REACT_APP_ENV !== 'production'}
            version={process.env.REACT_APP_VERSION}
            env={process.env.REACT_APP_ENV}
            fullVersion={JSON.stringify(VERSION, null, 2)}
          />
          <FormValidation>
            <BrowserRouter>
              <TelemetryProvider conectionString={conectionString}>
                <RootRouterConfig />
              </TelemetryProvider>
            </BrowserRouter>
          </FormValidation>
        </SnackbarProvider>

        <Loading />
      </ThemeProvider>
    </MsalProvider>
  );
}

export default App;
