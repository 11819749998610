import { createStyles, makeStyles, Theme } from '@material-ui/core';

const MenuItemStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuItem: {
      width: '186px',
      display: 'flex',
      alignItems: 'center',
      marginBottom: '16px',
      padding: '0.75rem 1.375rem',
      textDecoration: 'none',
      color: 'var(--blanco)',
      gap: '10px',
      transition: '0.3s ease all',
      [theme.breakpoints.down('sm')]: {
        padding: '0.75rem 2rem',
      },
    },
    menuItemUsuario: {
      display: 'flex',
      alignItems: 'center',
      padding: '1rem 1.5rem',
      textDecoration: 'none',
      color: 'var(--secundario-azul-marino)',
      transition: '0.3s ease all',
      '&:hover': {
        '--icon-primary-dark': 'var(--secundario-gris-azulado)',
        '--icon-primary-light': 'var(--secundario-azul-marino)',
      },
      [theme.breakpoints.down('sm')]: {
        padding: '0.75rem 2rem',
      },
    },
    menuItemMobile: {
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    menuItemActive: {
      '--icon-primary-dark': 'var(--blanco)',
      '--icon-primary-light': 'rgba(41, 40, 40, 1)',
      textDecoration: 'none',
      backgroundColor: 'var(--blanco)',
      color: 'rgba(41, 40, 40, 1)',
      borderRadius: '10px',
      '&:hover': {
        '--icon-primary-dark': 'var(--blanco)',
        '--icon-primary-light': 'rgba(41, 40, 40, 1)',
      },
      [theme.breakpoints.down('sm')]: {
        background: 'transparent',
        border: 'unset',
      },
    },
    menuItemActiveUsuario: {
      '--icon-primary-dark': 'var(--primario-morado)',
      '--icon-primary-light': 'var(--secundario-gris-azulado)',
      borderRight: '0.375rem solid var(--primario-morado)',
      background: 'hsla(var(--valor-primario-morado), 0.1)',
      color: 'var(--primario-morado)',
      '&:hover': {
        '--icon-primary-dark': 'var(--secundario-gris-azulado)',
        '--icon-primary-light': 'var(--primario-morado)',
      },
      [theme.breakpoints.down('sm')]: {
        background: 'transparent',
        border: 'unset',
      },
    },
    icon: {
      height: '24px',
      width: '24px',
      transition: '0.3s ease all',
    },
    iconUsuario: {
      marginRight: '0.625rem',
      transition: '0.3s ease all',
    },
    text: {
      whiteSpace: 'nowrap',
    },
    textUsuario: {
      color: 'hsla(var(--valor-secundario-azul-marino), 0.6)',
    },
  })
);

export default MenuItemStyles;
