import { HOME } from 'root-page/constants';
import { Route, Redirect, RouteProps } from 'react-router-dom';

interface ProtectedRouteProps extends RouteProps {
  component: React.ComponentType<any>;
  path: string;
  estadoLogin: boolean;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ component: Component, path, estadoLogin, ...props }) => {
  // Si la ruta es la de inicio de sesión, permite el acceso sin protección.
  if (path === `/${HOME}`) {
    return <Route path={path} component={Component} />;
  }
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Route {...props} render={routeProps => (estadoLogin ? <Component {...routeProps} /> : <Redirect to={HOME} />)} />;
};

export default ProtectedRoute;
