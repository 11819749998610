import '@saesa/ui-kit-front/lib/esm/assets/fonts/stylesheet.css';
import '@saesa/ui-kit-front/lib/esm/styles/colors.css';
import '@saesa/ui-kit-front/lib/esm/styles/reset.css';
import '@saesa/ui-kit-front/lib/esm/styles/styles.css';
import App from 'App';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { AuthInterceptor } from 'config';
import { AuthenticationResult, EventMessage, EventType, PublicClientApplication } from '@azure/msal-browser';
import { loginRequest, msalConfig } from 'config/authConfig';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { store } from './store';

sessionStorage.setItem('loading', '0');

AuthInterceptor();

/**
 * Objeto MSAL usado para interactuar con la libreria MSAL.
 */
export const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.initialize().then(() => {
  const accounts = msalInstance.getAllAccounts();

  if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
    msalInstance.acquireTokenSilent(loginRequest).then(response => {
      localStorage.setItem('token', JSON.stringify(response.accessToken));
    });
  }

  msalInstance.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
      const payload = event.payload as AuthenticationResult;
      const { account } = payload;
      msalInstance.setActiveAccount(account);
    }
  });

  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <App pca={msalInstance} />
      </Provider>
    </React.StrictMode>,
    document.getElementById('root')
  );
});
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
