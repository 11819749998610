import axios from 'axios';

const mostrarLoading = () => {
  let contador: string | number | null = sessionStorage.getItem('loading');
  contador = parseInt(contador!, 10) + 1;
  sessionStorage.setItem('loading', contador.toString());

  const loading = document.getElementById('loading');
  if (loading) {
    loading.classList.add('backdrop--show');
  } else {
    console.error("Elemento con ID 'cargando' no encontrado.");
  }
};

const AuthInterceptor = () => {
  axios.interceptors.request.use(request => {
    const apiKey = `${process.env.REACT_APP_HEADER_API_KEY}`;
    const token = localStorage.getItem('token');

    request.headers['x-api-key'] = apiKey;

    if (token !== null && token !== '') {
      request.headers.Authorization = `Bearer ${token}`;
    }

    mostrarLoading();
    return request;
  });
};

export default AuthInterceptor;
