import { Typography } from '@saesa/ui-kit-front';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, actualizarEstadoFlagApp, actualizarUrlSeleccionada } from 'store';
import { NavLink, useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { ModalTipoEnum } from 'config';
import ModalFeatureFlags from 'components/ModalBoletaDigital/ModalFeatureFlags';
import useStyles from './MenuItemStyles';

const MenuItem = ({
  label,
  icon,
  link,
  mobile,
  dataTestId,
  exact,
}: {
  label: string;
  icon: any;
  link: string;
  mobile?: boolean;
  dataTestId: string;
  exact?: boolean;
}) => {
  /**
   * Componente styles
   */
  const styles = useStyles();

  /**
   * Icono del menu
   */
  const Icon = icon;

  /**
   * Hook de react para disparar el redux
   */
  const dispatch = useDispatch();

  /**
   * Hook de react para manejar el historial
   */

  const history = useHistory();

  /**
   * Hook de react para manejar el estado del modal
   */
  const [modal, setModal] = useState(false);

  /**
   * Hook de react para manejar el estado de los cambios en los flags
   */
  const existenCambios = useSelector((state: RootState) => state.feature.estadoCambios);

  /**
   * Hook de react para manejar la url seleccionada por el usuario en el menu
   */
  const urlSeleccionada = useSelector((state: RootState) => state.feature.urlSeleccionada);

  /**
   * Función que permite salir sin guardar cambios
   * @param url que ha sido seleccionada por el usuario en el menu
   */
  const salirSinGuardar = (url: string) => {
    dispatch(actualizarEstadoFlagApp(false));
    setModal(false);
    history.block(null);
    history.push(url);
  };

  /**
   * Función que permite permanecer en la pagina y conservar los cambios
   */
  const volverALosCambios = () => {
    setModal(false);
  };

  /**
   * Link interno
   */
  const linkInterno = (
    <NavLink
      to={link}
      className={clsx(styles.menuItem, {
        [styles.menuItemMobile]: !mobile,
      })}
      exact={exact}
      activeClassName={styles.menuItemActive}
      onClick={() => {
        dispatch(actualizarUrlSeleccionada(link));
        if (existenCambios && history.location.pathname !== link) {
          setModal(true);
        }
      }}
      key={label}
      data-testid={dataTestId}
    >
      <Icon className={styles.icon} />
      <Typography strong="semi-bold" variant="parrafo" className={styles.text}>
        {label}
      </Typography>
    </NavLink>
  );

  /**
   * UseEffect que verifica si hay cambios
   * Si existen cambios bloquea el cambio de url
   * Si no existen cambios la url puede ser cambiada
   */
  useEffect(() => {
    if (existenCambios) {
      history.block();
    } else {
      history.block(null);
    }
  }, [existenCambios, history]);

  return (
    <>
      {linkInterno}
      {modal && (
        <ModalFeatureFlags
          cerrarModal={volverALosCambios}
          guardarCambios={() => salirSinGuardar(urlSeleccionada)}
          tipo={ModalTipoEnum.SALIR}
        />
      )}
    </>
  );
};

MenuItem.defaultProps = {
  mobile: true,
  exact: false,
};

export default MenuItem;
