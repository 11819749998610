import { createStyles, makeStyles, Theme } from '@material-ui/core';

const RootStyles = makeStyles((theme: Theme) =>
  createStyles({
    home: {
      position: 'relative',
      display: 'grid',
      gridTemplateColumns: '16.5vw 83.5vw',
      gridTemplateRows: '70px auto ',
      gridTemplateAreas: `
          "sidemenu header "
          "sidemenu dashboard "
          "sidemenu dashboard "
        `,
      minHeight: '100vh',
      maxWidth: '100vw',
      overflowX: 'hidden',
      [theme.breakpoints.down('sm')]: {},
    },

    header: {
      background: 'rgba(41, 40, 40, 1)',
      gridArea: 'header',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      padding: '16px 32px 16px 32px',
      paddingRight: '49px',
      position: 'absolute',
      justifyContent: 'center',
      zIndex: 1,
      width: '83.5vw',
      height: '70px',
      [theme.breakpoints.down('sm')]: {},
    },

    sidemenu: {
      gridArea: 'sidemenu',
    },
    dashboard: {
      width: '83.5vw',
      gridArea: 'dashboard',
      zIndex: 1,
      background: 'rgba(30, 30, 30, 1)',
    },
  })
);

export default RootStyles;
