import { Configuration, PopupRequest } from '@azure/msal-browser';

/**
 * MSAL configuration object.
 */
export const msalConfig: Configuration = {
  auth: {
    clientId: `${process.env.REACT_APP_CLIENT_ID}`,
    authority: 'https://login.microsoftonline.com/136d6ab5-5b55-496f-97cb-2424247715ed',
    redirectUri: '/',
    postLogoutRedirectUri: '/',
  },
  system: {
    allowNativeBroker: false, // Disables WAM Broker
  },
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 */
export const loginRequest: PopupRequest = {
  scopes: ['User.read'],
};

/**
 * Scopes you add here will be used to request a token from Azure AD to be used for accessing a protected resource.
 */
export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
};
