import { createStyles, makeStyles, Theme } from '@material-ui/core';

const HeaderStyles = makeStyles((theme: Theme) =>
  createStyles({
    contenedorInterno: {
      display: 'flex',
      gap: '5px',
      [theme.breakpoints.down('sm')]: {},
    },
    textoHeader: {
      fontFamily: 'Neutrif Studio',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '18px',
      textAlign: 'right',
      color: '#CCCCCC',
      flex: 'none',
      alignContent: 'center',
      display: 'flex',
      flexWrap: 'wrap',
      flexGrow: 0,
      marginRight: '13px',
    },
    botonCerrarSesion: {
      background: 'none',
      border: 'none',
      cursor: 'pointer',
      padding: '0px',
    },
  })
);

export default HeaderStyles;
