import { createStyles, makeStyles, Theme } from '@material-ui/core';

const ModalFeatureFlagsStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      display: 'block',
      position: 'fixed',
      top: '0',
      bottom: '0',
      left: '0',
      right: '0',
      background: 'hsla(var(--gris-l3-h), var(--gris-l3-s), var(--gris-l3-l), 0.7)',
      transition: 'var(--transition) opacity',
      zIndex: 3,
      [theme.breakpoints.down('sm')]: {
        zIndex: 2,
      },
    },
    modalGlobal: {
      position: 'fixed',
      top: '50%',
      left: '50%',
      padding: '44px',
      borderRadius: '0.625rem',
      background: 'rgba(30, 30, 30, 1)',
      boxShadow: '0px 2px 34px rgba(9, 39, 84, 0.09)',
      transform: 'translate(-50%, -50%)',
      zIndex: 4,
      gap: '32px',
      display: 'flex',
      flexDirection: 'column',
    },

    modalGuardarCambios: {
      width: '451px',
      height: '283px',
      [theme.breakpoints.down('sm')]: {
        width: '90%',
        height: '335px',
        padding: '44px 20px',
      },
    },

    contenedorGlobal: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '0px',
      gap: '24px',
      flex: 'none',
      order: 0,
      alignSelf: 'stretch',
      flexGrow: 0,
      zIndex: 0,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },

    contenedorTextosGlobal: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '0px',
      gap: '12px',
      flex: 'none',
      order: 1,
      alignSelf: 'stretch',
      flexGrow: 0,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },

    tituloTextos: {
      width: '316px',
      fontFamily: 'Neutrif Studio',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: '20px',
      textAlign: 'center',
      color: ' #FFFFFF',
      flex: 'none',
      order: 0,
      flexGrow: 0,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },

    parrafoGlobal: {
      fontFamily: 'Neutrif Studio',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '18px',
      color: '#CCCCCC',
      flex: 'none',
      alignSelf: 'stretch',
      flexGrow: 0,
      textAlign: 'center',
    },

    contenedorBotones: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'flex-start',
      padding: '0px',
      gap: '16px',
      width: '100%',
      height: '45px',
      flex: 'none',
      order: 1,
      flexGrow: 0,
      zIndex: 1,
      [theme.breakpoints.down('sm')]: {
        maxWidth: '100%',
      },
    },
  })
);

export default ModalFeatureFlagsStyles;
