import ReactDOM from 'react-dom';
import { Button, styled } from '@mui/material';
import { Typography } from '@saesa/ui-kit-front';
import { useTranslation } from 'react-i18next';
import {
  obtenerIconoModal,
  obtenerSubTituloModal,
  obtenerTextoBotonCancelar,
  obtenerTextoPrimerBoton,
  obtenerTituloModal,
} from './FuncionesModalFeatureFlags';
import ModalFeatureFlagsStyles from './ModalFeatureFlagsStyles';

const ModalFeatureFlags = ({
  guardarCambios,
  cerrarModal,
  tipo,
}: {
  guardarCambios: () => void;
  cerrarModal: () => void;
  tipo: string;
}) => {
  /**
   * Selecciona el elemento modal
   */
  const element = document.getElementById('modal')!;

  /**
   * Componente styles
   */
  const styles = ModalFeatureFlagsStyles();

  /**
   * Componente de traduccion i18n
   */
  const { t } = useTranslation();

  /**
   * Botón personalizado de continuar proceso
   */
  const BottonContinuarProceso = styled(Button)({
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 16,
    padding: '14px 32px 13px 32px',
    border: '1px solid',
    height: '45px',
    borderRadius: '26px',
    lineHeight: 1.5,
    fontWeight: 600,
    backgroundColor: 'var(--primario-morado)',
    borderColor: 'var(--primario-morado)',
    fontFamily: ['Neutrif Studio'].join(','),
    '&:hover': {
      backgroundColor: 'var(--morado-l36)',
      borderColor: 'var(--morado-l36)',
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: 'var(--primario-morado)',
      borderColor: 'var(--primario-morado)',
    },
  });

  /**
   * Botón personalizado de cancelar proceso
   */
  const BottonCancelarProceso = styled(Button)({
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 16,
    padding: '14px 32px 13px 32px',
    border: '2px solid',
    height: '45px',
    borderRadius: '26px',
    lineHeight: 1.5,
    fontWeight: 600,
    backgroundColor: 'rgba(30, 30, 30, 1)',
    borderColor: 'var(--blanco)',
    fontFamily: ['Neutrif Studio'].join(','),
    '&:hover': {
      backgroundColor: '#343333',
      borderColor: 'var(--blanco)',
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: 'rgba(30, 30, 30, 1)',
      borderColor: 'var(--blanco)',
    },
  });

  return element
    ? ReactDOM.createPortal(
        <div className={styles.backdrop}>
          <section className={`${styles.modalGlobal} `}>
            <div className={`${styles.contenedorGlobal}`}>
              <img src={obtenerIconoModal(tipo)} alt="Guardar información" />
              <div className={`${styles.contenedorTextosGlobal}`}>
                <Typography className={styles.tituloTextos}>{obtenerTituloModal(tipo, t)}</Typography>
                <Typography className={styles.parrafoGlobal}>{obtenerSubTituloModal(tipo, t)}</Typography>
              </div>
            </div>
            <div className={styles.contenedorBotones}>
              <BottonCancelarProceso type="button" variant="contained" color="primary" onClick={cerrarModal} data-testid="cerrarModal">
                {obtenerTextoBotonCancelar(tipo, t)}
              </BottonCancelarProceso>

              <BottonContinuarProceso type="button" variant="contained" color="primary" onClick={guardarCambios} data-testid="botonAceptar">
                {obtenerTextoPrimerBoton(tipo, t)}
              </BottonContinuarProceso>
            </div>
          </section>
        </div>,
        element
      )
    : null;
};

export default ModalFeatureFlags;
